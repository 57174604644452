.title_top_box {
  height: auto;
  background-color: #ce9664;
  padding-bottom: 15px;
}

.title_top_box2 {
  height: auto;
  background-color: #403e3d;
  padding-bottom: 15px;
}

.title_top_box3 {
  height: auto;
  background-color: #d4a378;
  padding-bottom: 15px;
}

.menu_title {
  font-family: 'Cormorant Infant', serif;
  color: #fff;
  font-size: 60px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 50px;
}

.menu_desc {
  font-family: 'Cormorant Infant', serif;
  color: #fff;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: normal;
  position: relative;
  top: -5px;
}

.fundo_prod {
  background: #faf9f2;
  position: relative;
}

.container_prod {
  padding-top: 20px;
  padding-bottom: 20px;
}

.produto_row {
  margin:0;
  padding-left: 25px;
  padding-right: 5px;
}

.produto_pad {
  margin-right: 10px;
  margin-top: 10px;
  padding:0;
  position: relative;
  display: inline-block;
}

.produto_big {
  width: 262px;
  height: 341px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.p_target0 {
  width: 262px;
  height: 4px;
  position: absolute;
  background: #faf9f2;
  top: 0;
  left: 0;
  z-index: 210;
}

.p_target1 {
  width: 262px;
  height: 341px;
  position: absolute;
  background: #faf9f2;
  top:-4px;
  left:0;
  z-index: 190;
}

.p_target2 {
  width: 100px;
  height: 50px;
  position: absolute;
  background: #faf9f2;
  bottom:0;
  left:0;
  z-index: 190;
}

.p_target3 {
  width: 100px;
  height: 50px;
  position: absolute;
  background: #faf9f2;
  bottom:0;
  right:0;
  z-index: 190;
}

.p_hidden0 {
  width: 262px;
  height: 4px;
  position: absolute;
  background: #faf9f2;
  top: 0;
  left: 0;
  z-index: 210;
  animation-name: top_box1;
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: forwards;
  animation-delay: 0.4s;
}

.p_hidden1 {
  width: 262px;
  height: 341px;
  position: absolute;
  background: #faf9f2;
  top:-4px;
  left:0;
  z-index: 190;
  animation-name: top_box2;
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: forwards;
  animation-delay: .2s;
}

.p_hidden2 {
  width: 100px;
  height: 50px;
  position: absolute;
  background: #faf9f2;
  bottom:0;
  left:0;
  z-index: 190;
  animation-name: left_box;
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: forwards;
}

.p_hidden3 {
  width: 100px;
  height: 50px;
  position: absolute;
  background: #faf9f2;
  bottom:0;
  right:0;
  z-index: 190;
  animation-name: right_box;
  animation-duration: .2s;
  animation-play-state: paused;
  animation-fill-mode: forwards;
}

@keyframes right_box {
  0%   { right: 0; }
  100% { right: -100px; }
}

@keyframes left_box {
  0%   { left: 0; }
  100% { left: -100px; }
}

@keyframes top_box2 {
  0%   { top:-4px; }
  100% { top:-341px; }
}

@keyframes top_box1 {
  0%   { width: 262px; }
  100% { width: 0; }
  0%   { left: 0; }
  100% { left: 135px; }
}

.produto_big:hover .p_hidden3 {
  animation-play-state: running;
}
.produto_big:hover .p_hidden2 {
  animation-play-state: running;
}
.produto_big:hover .p_hidden1 {
  animation-play-state: running;
}
.produto_big:hover .p_hidden0 {
  animation-play-state: running;
}

.p_hidden0_1 {
  width: 262px;
  height: 4px;
  position: absolute;
  background: #faf9f2;
  top: 0;
  left: 135px;
  z-index: 210;
  animation-name: top_box1_1;
  animation-duration: .2s;
  animation-play-state: running;
  animation-fill-mode: forwards;
}

.p_hidden1_1 {
  width: 262px;
  height: 341px;
  position: absolute;
  background: #faf9f2;
  top:-341px;
  left:0;
  z-index: 190;
  animation-name: top_box2_1;
  animation-duration: .2s;
  animation-play-state: running;
  animation-fill-mode: forwards;
  animation-delay: .2s;
}

.p_hidden2_1 {
  width: 100px;
  height: 50px;
  position: absolute;
  background: #faf9f2;
  bottom:0;
  left: -100px;
  z-index: 190;
  animation-name: left_box_1;
  animation-duration: .2s;
  animation-play-state: running;
  animation-fill-mode: forwards;
  animation-delay: .4s;
}

.p_hidden3_1 {
  width: 100px;
  height: 50px;
  position: absolute;
  background: #faf9f2;
  bottom:0;
  right: -100px;
  z-index: 190;
  animation-name: right_box_1;
  animation-duration: .2s;
  animation-play-state: running;
  animation-fill-mode: forwards;
  animation-delay: .4s;
}

@keyframes right_box_1 {
  0%   { right: -100px; }
  100% { right: 0;}
}

@keyframes left_box_1 {
  0%   { left: -100px; }
  100% { left: 0; }
}

@keyframes top_box2_1 {
  0%   { top:-341px; }
  100% { top:-4px;}
}

@keyframes top_box1_1 {
  0%   { width: 0px; }
  100% { width: 262px; }
  0%   { left: 135px; }
  100% { left: 0; }
}

.produto_box {
  width: 262px;
  height: 341px;
  outline: 4px solid #ce9664;
  outline-offset: -4px;
}

.produto_box img {
  width: 254px;
  height: 254px;
  position: absolute;
  left: 4px;
  top: 4px;
  z-index: 200;
  @include transition;
}

.prod_text {
  width: 86%;
  position: absolute;
  left: 50%;
  margin-left: -43%;
  bottom: 25px;
  z-index: 250;
}

.prod_title {
  font-size: 18px;
  font-weight: bold;
  color: #7d7d7d;
  text-transform: uppercase;
  cursor: pointer;
  line-height: 22px;
  @include transition2;
}

.prod_desc {
  font-size: 13px;
  color: #7d7d7d;
  margin-top: 4px;
  cursor: pointer;
}

.produto_big:hover .prod_title {
  color: #ce9664;
}

.produto_big_banner1 {
  width: 262px;
  height: 341px;
  overflow: hidden;
  position: relative;
  outline: 30px solid #edd9c8;
  outline-offset: -30px;
  @include transition2;
}

.banner1_icon1 {
  width: 47px;
  height: 82px;
  margin-top: 41px;
}

.produto_big_banner1 h3 {
  font-family: 'Cormorant Infant', serif;
  font-size: 20px;
  color: #ce9664;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 10px;
}

.produto_big_banner1 h4 {
  font-family: 'Cormorant Infant', serif;
  font-size: 14px;
  color: #ce9664;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 3px;
}

.produto_big_banner1 p {
   font-size: 14px;
   color: #7d7d7d;
   margin-top: 15px;
   padding: 0 45px;
 }

.banner1_icon_box {
  width: 56px;
  height: 56px;
  margin-top: 2px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.banner1_icon2 {
  width: 56px;
  height: 56px;
  position: absolute;
  top:0;
  left:0;
}

.banner1_icon3 {
  width: 56px;
  height: 56px;
  position: absolute;
  top:0;
  left:0;
  opacity: 0;
  @include transition2;
}

.produto_big_banner1:hover {
  outline: 30px solid #ce9664;
}

.banner1_icon_box:hover .banner1_icon3 {
  opacity: 1;
}

.produto_big_banner2 {
  width: 537px;
  height: 341px;
  overflow: hidden;
  position: relative;
}

.banner2_img_border {
  width: 537px;
  height: 341px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  outline: 30px solid rgba(206,150,100,0.5);
  outline-offset: -30px;
  z-index: 600;
  @include transition2;
  pointer-events: none;
}

.produto_big_banner2 img {
  width: 537px;
  height: 341px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.produto_big_banner2 h3 {
  font-family: 'Cormorant Infant', serif;
  font-size: 60px;
  color: #ce9664;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 60px;
  margin-left: 55px;
  position: relative;
  width: 362px;
  line-height: 50px;
  text-align: left;
}

.produto_big_banner2 h4 {
  font-family: 'Cormorant Infant', serif;
  font-size: 20px;
  color: #7d7d7d;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 9px;
  margin-left: 57px;
  position: relative;
  text-align: left;
}

.banner2_btn {
  position: relative;
  left: -116px;
  margin-top: 35px;
  text-align:left !important
}

.produto_big_banner2:hover .banner2_img_border  {
  outline: 30px solid rgba(206,150,100,1);
}

.filtros {
  width: 85%;
  height: 50px;
  margin-top: 20px;
  margin-left: 90px;
  padding-right: 0px;
  padding-top: 10px;
  border-bottom: 1px solid #dad4cf;

}

.filtros ul {
  text-align: left;
}

.filtros li {
  font-size: 14px;
  color: #002f6d;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  margin-left: 14px;
  margin-right: 10px;
}

.filtros a {
  font-size: 14px;
  color: #002f6d;
  text-transform: uppercase;
  @include transition;
}

.filtros a:hover {
  color: #CE9664;
}

.filtros_first {
  margin-left: 0px;
}

.filtros_last:last-child {
  float: right;
  color: #7d7d7d;
  font-weight: bold;
}

.filtros_last:last-child a {
  float: right;
  color: #7d7d7d;
  font-weight: bold;
}

.filtros_last:last-child a:hover {
  color: #CE9664;
}

.filtros_autor {
  width: 85%;
  height: 50px;
  margin-top: 20px;
  margin-left: 90px;
  padding-right: 0px;
  padding-top: 10px;
  border-bottom: 1px solid #dad4cf;

}

.filtros_autor ul {
  text-align: left;
}

.filtros_autor li {
  font-size: 14px;
  color: #002f6d;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  margin-left: 20px;
  margin-right: 10px;
}

.filtros_autor a {
  font-size: 14px;
  color: #002f6d;
  text-transform: uppercase;
  @include transition;
}

.filtros_autor a:hover {
  color: #CE9664;
}

.filtros_autor li:first-child {
  margin-left: 0px;
}

.drop_icon {
  font-size: 20px;
  margin-left: 5px;
  margin-top: 5px;
}

.prod_btn {
  text-align: center;
  margin-top: 80px;
  margin-bottom: 40px;
}

.nav_in {
  height: 65px;
  border-bottom: 1px solid #dad4cf;
  padding-top: 40px;
}
.nav_in ul {
  text-align: left;
}

.nav_in li {
  display: inline-block;
  margin-right: 5px;
  font-size: 14px;
  color: #dad4cf;
  text-transform: uppercase;
}

.nav_in a {
  font-size: 14px;
  color: #dad4cf;
  text-transform: uppercase;
  @include transition;
}

.nav_in a:hover {
  color: #CE9664;
}

.nav_in li:last-child {
  color: #002f6d;
}

.nav_in span {
  margin-right: 5px;
  color: #dad4cf;
}

.prod_in img {
  display: inline-block;
  margin-top: 47px;
  margin-left: 13px;
}

.prod_in h1 {
  color: #ce9664;
  font-size: 30px;
  line-height: 35px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 30px;
}

.prod_desc_sep {
  background: #dfd8d2;
  width: 74px;
  height: 4px;
  margin-top: 10px;
}

.or_ref {
   color: #7d7d7d;
   font-size: 12px;
   text-transform: uppercase;
   font-weight: bold;
   margin-top: 15px;
 }

.or_text {
  width: 85%;
  color: #7d7d7d;
  font-size: 14px;
  margin-top: 15px;
}

.prod_sub {
  color: #ce9664;
  font-size: 20px;
  line-height: 35px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 15px;
}

.or_text_small p {
  width: 85%;
  color: #7d7d7d;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 10px;
}

.or_text_small span {
  font-weight: normal;
  text-transform: none;
}

.presente_box {
   width: 85%;
   height: 72px;
   border-top: 2px solid #f3e4d8;
   border-bottom: 2px solid #f3e4d8;
   margin-top: 20px;
   padding-top: 7px;
}

.icon_pres {
  font-size: 40px;
  color: #7d7d7d;
  float: left;
}

.text_pres {
  width: 200px;
  color: #7d7d7d;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 12px;
  float: left;
  margin-left: 16px;
}

.text_pres span {
  font-weight: normal;
}

.icon_pres2 p {
  font-size: 20px;
  color: #aea8a4;
  float: right;
  margin-top: 12px;
  margin-left: 15px;
  cursor: pointer;
}

.icon_pres2 p:hover {
  color: #CE9664;
}

.pres_btn {
  margin-top: 40px;
  margin-left: -25px;
  margin-bottom: 40px;
}

.middle_title {
  margin-top: 20px;
}


.middle_title h2 {
  font-weight: bold;
  font-size: 60px;
  color: #ce9664;
  text-transform: uppercase;
}

.middle_title h3 {
  font-size: 30px;
  color: #7d7d7d;
  text-transform: uppercase;
}

.serv_rel_cont {
  margin-bottom: 60px;
}

.serv_rel_box {
  position: relative;
  margin-top: 40px;
  padding-left: 15px;
  padding-right: 15px;
  left: -15px;
  margin-left: 15px;
}

.serv_rel_box2 {
  position: relative;
  margin-top: 0px;
  padding-left: 15px;
  padding-right: 15px;
  right: -15px;
  margin-left: 15px;
  margin-right: 15px;
}

.serv_rel {
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 360px;
   outline: 30px solid rgba(64,62,61,.5);
   outline-offset: -30px;
   z-index: 400;
   margin-left: 15px;
   margin-right: 15px;
 }

.serv_rel2 {
  position: absolute;
  right: 0;
  top: 0;
  width: 102.7%;
  height: 360px;
  outline: 30px solid rgba(206,150,100,.5);
  outline-offset: -30px;
  z-index: 400;
  margin-left: 15px;
  margin-right: -15px;
}

.serv_rel_box img {
  position: relative;
  z-index: 300;
  width: 570px;

}

.serv_rel_box2 img {
  position: relative;
  z-index: 300;
  width: 570px;
}


.serv_rel_text {
   position: relative;
   z-index: 300;
   width: 100%;
   text-align: center;
 }

.small_title {
  margin-top: 70px;
}

.serv_rel_text p {
  font-size: 14px;
  color: #7d7d7d;
  width: 392px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.rel_icons {
   position: absolute;
   top: 91px;
   z-index: 450;
   left: 40%;
 }

.autor_title {
  color: #ce9664;
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  text-transform: uppercase;
  margin-top: 150px;
}

.autor_desc {
  color: #7d7d7d;
  font-size: 18px;
  font-weight: normal;
  line-height: 25px;
  margin-top: 15px;
}

.autor_button {
  margin-top: 75px;
  margin-left: 8px;
  margin-bottom: 50px;
}

.autor_img {
  margin-top: 78px;
}

.bg-quemsomos2 {
  background-color: #fff;
}

.subtitle_gallery {
  color: #7d7d7d;
  font-size: 30px;
  font-weight: normal;
  margin-top: 40px;
  margin-bottom: 40px;
  text-transform: uppercase;
}

.gallery_small {
  position: relative;
  width: 263px;
  height: 360px;
  margin-right: 30px;
  margin-bottom: 30px;
  overflow: hidden;
}

.gallery_small2 {
  position: relative;
  width: 263px;
  height: 360px;
  margin-bottom: 30px;
  overflow: hidden;
}

.gallery_big {
  position: relative;
  width: 555px;
  height: 360px;
  margin-bottom: 30px;
  overflow: hidden;
}

.gallery_big2 {
  position: relative;
  width: 555px;
  height: 360px;
  margin-right: 30px;
  margin-bottom: 30px;
  overflow: hidden;
}

.gallery_border {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0; left: 0; bottom: 0; right: 0;
  outline: 30px solid rgba(174,168,164,.5);
  outline-offset: -30px;
  z-index: 450;
  pointer-events: none;
  @include transition2;
}

.gallery_small:hover .gallery_border {
  outline: 30px solid rgba(174,168,164,1);
}

.gallery_big:hover .gallery_border {
  outline: 30px solid rgba(174,168,164,1);
}

.gallery_small2:hover .gallery_border {
  outline: 30px solid rgba(174,168,164,1);
}

.gallery_big2:hover .gallery_border {
  outline: 30px solid rgba(174,168,164,1);
}

.button_visite {
  margin-top:30px;
  margin-bottom: 60px;
}

.gallery_row {
  margin-left:0;
}

.ferr_text {
  color: #7d7d7d;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  margin-top: 100px;
}

.bg-artigos {
  background-color: #f4f3ec;
  padding-top: 40px;
  padding-bottom: 40px;

}

.artigos_text {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: left;

}

.artigos_text p {
  font-size: 14px;
  color: #fff;
  line-height: 21px;
  margin-bottom: 0;

}

.artigos_left {
  width: 50%;
  height: 360px;
  float: left;
  background: #999793;
  padding-left: 20px;
  padding-right: 20px;
}

.artigos_right {
  width: 50%;
  height: 360px;
  float: right;
  background: #999793;
  padding-left: 20px;
  padding-right: 20px;
}

.artigos_left_img {
  width: 50%;
  height: 360px;
  float: left;
  position: relative;
  z-index: 450;
}

.artigos_right_img {
  width: 50%;
  height: 360px;
  float: right;
  position: relative;
  z-index: 450;
}

.artigos_hover {
  width: 100%;
  height: 360px;
  background: #ce9664;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 500;
  opacity: 0;
  cursor: pointer;
  @include transition2;
}

.artigos_hover:hover {
  opacity: 0.85;
}

.artigos_hover2 {
  width: 100%;
  height: 360px;
  background: #ce9664;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 500;
  opacity: 0;
  cursor: pointer;
  @include transition2;
}

.artigos_hover2:hover {
  opacity: 0.85;
}

.plus_bottom_ferr {
  background-image: url("../images/plus_bottom.svg");
  width: 56px;
  height: 56px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 150px;

}

.ferr_btn {
  margin-top: 60px;
  margin-bottom: 30px;

}

.banhos_text {
  width: 510px;
  color: #7d7d7d;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  margin-top: 55px;
  margin-left: auto;
  margin-right: auto;
}

.banhos_text2 {
  width: 740px;
  color: #7d7d7d;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.slider_change {
  margin-top: 30px;
  position: relative;
  margin-left: 0;
  margin-bottom: 40px;
}

.slider_change_b {
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width: 1138px;
  height: 458px;
  outline: 30px solid rgba(64,62,61,0.5);
  outline-offset: -30px;
  z-index: 500;
  pointer-events: none;
}

.ba-slider {
  position: relative;
  width: 1138px;
  height: 458px;
  overflow: hidden;
}

.ba-slider img {
  width: 1138px;
  height: 458px;
  display:block;
}

.resize {
  position: absolute;
  top:0;
  left: 0;
  height: 100%;
  width: 50%;
  overflow: hidden;
}


.handle { /* Thin line seperator */
  position:absolute;
  left:50%;
  top:0;
  bottom:0;
  width:6px;
  margin-left:-2px;

  background: #ce9664;
  cursor: ew-resize;
  z-index: 510;
}

.handle:after {  /* Big orange knob  */
  position: absolute;
  top: 50%;
  width: 64px;
  height: 64px;
  margin: -32px 0 0 -32px;

  content:'\2194';
  color:white;
  font-weight:bold;
  font-size:36px;
  text-align:center;
  line-height:57px;

  background: #ce9664; /* @orange */
  border-radius: 50%;
  transition:all 0.3s ease;

}

.draggable:after {
  width: 56px;
  height: 56px;
  margin: -28px 0 0 -28px;
  line-height:51px;
  font-size:30px;
}

.banhos_img1 {
  width: 555px;
  height: 360px;
  outline: 30px solid rgba(174,168,164,0.5);
  outline-offset: -30px;
  @include transition2;
}

.banhos_img1:hover {
  outline: 30px solid rgba(174,168,164,1);
}

.banhos_title {
  margin-top: 40px;
}

.banhos_btn {
  margin-top: 60px;
  margin-bottom: 60px;

}

.slider_change_left {
  background-color: #ce9664;
  padding: 10px 20px 10px 20px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  opacity: 0.5;
  position: absolute;
  left: 0;
  top: 45%;
  z-index: 501;

}

.slider_change_right {
  background-color: #ce9664;
  padding: 10px 20px 10px 20px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  opacity: 0.5;
  position: absolute;
  right: 17px;
  top: 45%;
  z-index: 501;

}

.cons_text {
  font-size: 25px;
  font-weight: normal;
  color: #7d7d7d;
  margin-top: -2px;
}

.cons_bg {
  background: #f4f3ec;
  margin-top: 20px;
  padding-bottom: 60px;
}

.cons_img {
  margin-left: -120px;
}

.info_box {
  position: relative;
  padding-bottom: 46px;

}

.info_text {
  width: 649px;
  color: #7d7d7d;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  margin-top: 55px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 5;

}

.info_img {
  position: absolute;
  top:0;
  right: -360px;

}

.info_form {
  margin-top: 40px;
  margin-left: 100px;

}

.info_btn {
  margin-top: 40px;
  margin-left: 253px;
  position: relative;
  z-index: 10;

}

.error_box {
  width: 103%;
  text-align: center;
  padding: 67px 0;

}

.out_error {
  width: 100%;
  height: 512px;
  border: 4px solid #fff;

}

.out_error_in {

  height: 84%;
  border: 4px solid #fff;
  margin: 40px;

}

.error_desc {

  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin-top: 40px;

}

.error_desc span {

  font-weight: normal;

}

.error_btn {

  margin-top: 40px;

}

.dropdown-menu {
  z-index: 600
}

.filtros_col_box {
  position: relative;
  width: auto;
  height: auto;
  float: right;
  right: 70px;
  margin-top: -1px;
  border: 1px solid #dad4cf;
}

.filtros_col_box p {
  font-size: 14px;
  color: #7d7d7d;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 15px;
  margin-left: 30px;
  padding-top: 12px;
}

.filtros_col_box input {
  display: inline-block;
  padding-top: 2px;
  font-size: 14px;
  color: #7d7d7d;
}

.filtros_col_box span {
  padding-right: 10px;
}

.filtros_col_sep {
  width: 2px;
  background: #7d7d7d;
  height: 16px;
  display: inline-block;
  padding-top: 2px;
  margin-left: 20px;
}

.filtros_col_box ul {
  text-align: left;
}

.filtros_col_box a {
  font-size: 14px;
  color: #002f6d;
  text-transform: uppercase;
  @include transition;
}

.filtros_col_box a:hover {
  color: #CE9664;
}

.filtros_col_first {
  font-size: 14px;
  color: #7d7d7d;
  cursor: pointer;
  display: inline-block;
  margin-left: 20px;
  margin-right: 10px;
}

.filtros_col_first a {
  font-size: 14px;
  color: #7d7d7d;
  cursor: pointer;
  text-transform: none;
  display: inline-block;
  margin-left: -20px;
  margin-right: 10px;
}

.check_mob {
  display: inline-block;
}