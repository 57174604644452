@import "../css/bootstrap.min.css";
@import "../css/plugins/slick.css";
@import "../css/plugins/slick-theme.css";
@import "../css/animate.min.css";
@import url('https://fonts.googleapis.com/css?family=Cormorant+Infant:400,700|Rubik');


@import "base/fonts";
@import "base/variaveis";


@import "helpers/mixins";


@import "components/buttons";
@import "components/navigation";
@import "components/plugins";


@import "layout/banner";
@import "layout/pages";
@import "layout/header";
@import "layout/footer";


@import "pages/homepage";
@import "pages/quemsomos";
@import "pages/site";


@import "mediaqueries/mediaqueries";