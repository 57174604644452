body, html {
    font-family: 'Rubik', sans-serif;
    width: 100% !important;
    background-color: #FAF9F4;
    overflow-x: hidden;
}

a:link {text-decoration: none; outline: none; border: none; border-style: none;}
a:visited {text-decoration: none; outline: none; border: none; border-style: none;}
a:hover {text-decoration: none; outline: none; border: none; border-style: none;}
a:active {text-decoration: none; outline: none; border: none; border-style: none;}

footer {
    z-index: 20;
}

button {

    border: 0px none;
    background-color: transparent;
    padding: 0px;
    outline: none;
}

.news_none {
    display:block;
}

.modal {

    z-index: 10000;
}

.modal-backdrop {
    background-color: #CE9664 !important;
    opacity:1 !important;
}

#myModal {

    z-index: 10000;
}

#map {
    width: 100%;
    height: 410px;
}

.page_back {
    background: #faf9f2;
}

.small_title {
    font-size: 16px;
    font-weight: bold;
    color: #ce9664;
    text-transform: uppercase;
}

.button {
    color: #FFF;
    font-weight: bold;
    font-size: 12px;
    background-color: #8894b5;
    text-transform: uppercase;
    padding: 7px 39px 7px 39px;
    display:inline-block;
    border: 4px solid #8894b5;
    outline: 4px solid #8894b5;
    outline-offset: 5px;
    cursor: pointer;
    text-align: center;
    @include transition;
}

.button:hover {
    background-color: #002f6d;
    outline-offset: 0;
    outline: 4px solid #002f6d;
    border: 4px solid #002f6d;
}

.button_dark {
    color: #FFF;
    font-weight: bold;
    font-size: 12px;
    background-color: #313131;
    text-transform: uppercase;
    padding: 7px 39px 7px 39px;
    display:inline-block;
    border: 4px solid #313131;
    outline: 4px solid #313131;
    outline-offset: 5px;
    cursor: pointer;
    @include transition;
}

.button_dark:hover {
    background-color: #000;
    outline-offset: 0;
    outline: 4px solid #000;
    border: 4px solid #000;
}

.darkest-bg {
    background-color: #403e3d;
}

.dark-bg {
    background-color: #464646;
}

.darker-bg {
    background-color: #313131;
}

.border-wrapper {
    z-index: 20;
    position: absolute;
    top: 70px;
    left: 50%;
    height: 100vh;
    border: 20px solid rgba(62, 60, 59, 0.07);
    transform: translate(-50%);
}

figure {
    img {
        width: 100%;
    }
}

.pr {
    position: relative;
}

.subtitle {
    margin-bottom: 20px;
    font-size: 24px;
}

.text {
    margin-top: 20px;
    color:  #7d7d7d;
    line-height: 17px;
}

.img-wrapper {
    margin: 20px 0px;
}

.container-small, .container-extrasmall {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width : 1200px){
    .container-small {
        width: 1005px;
    }
    .container-extrasmall {
        width: 830px;
    }
}

.h1, .h2, .h3, h1, h2, h3 {
    margin: 0;
}

ul {
    padding-left: 0px;
}

.display-none {
    display: none;
}

.red {
    color: #d44950;
}

.green {
    color: #3c763d;
}

ul {
    margin-bottom: 0px;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}
.margin-top-60 {
    margin-top: 60px;
}
.margin-top-70 {
    margin-top: 70px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}
.margin-bottom-60 {
    margin-bottom: 60px;
}
.margin-bottom-70 {
    margin-bottom: 70px;
}

.nopadding{padding: 0 !important;}
.nopadding_left{padding-left: 0 !important;}
.nopadding_right{padding-right: 0 !important;}

.active{
    font-weight: bold;
}

/*LOAD SPINNER STYLES*/

.load {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: #ce9664;
    overflow:hidden !important;
}

.sk-cube-grid {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateY(-50%);
    transform: translateX(-50%);
}

.sk-cube-grid .sk-cube {
    width: 33%;
    height: 33%;
    background-color: #fff;
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s; }
.sk-cube-grid .sk-cube3 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s; }
.sk-cube-grid .sk-cube4 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s; }
.sk-cube-grid .sk-cube8 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    } 35% {
          -webkit-transform: scale3D(0, 0, 1);
          transform: scale3D(0, 0, 1);
      }
}

@keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    } 35% {
          -webkit-transform: scale3D(0, 0, 1);
          transform: scale3D(0, 0, 1);
      }
}

.msg_green {
    color: #8cc63f;
}

.msg_orange {
    color: #CE9664;
}

.msg_red {
    color: #ff0000;
}

.status_msg_footer {
    position: absolute;
    right: 15px;
    margin-top: 24px;
}

.status_msg_footer i {
    margin-right: 5px;
}

.status_msg_news {
    position: absolute;
    right: 13px;
    margin-top: 86px;
}

.status_msg_news i {
    margin-right: 5px;
}

.status_msg_info1 {
    position: absolute;
    left: 431px;
    margin-top: -27px;
    z-index: 500
}

.status_msg_info1 i {
    margin-right: 5px;
}