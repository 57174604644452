.banner-home-slider {
    width: 1920px;
    height: 432px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    .banner-home-element {
        .banner-home-box {
            display: block;
            position: relative;
            figure {
                img {
                    width: 1920px;
                    height: 432px;
                }
            }
            .info-content-container {
                position: absolute;
                top: 25%;
                left: 17%;
                z-index: 2;
                .info-content {
                    .title {
                        font-family: "Cormorant Infant";
                        font-weight: bold;
                        font-size: 60px;
                        text-transform: uppercase;
                        color: $banner-title-color;
                    }
                    .subtitle {
                        font-family: "Cormorant Infant";
                        font-weight: normal;
                        font-size: 30px;
                        text-transform: uppercase;
                        color: $banner-subtitle-color;
                    }

                }
            }
        }
    }
}

.title_banner {
    margin-top: 57px;
    margin-left: 135px;
}

.subtitle_banner {
    position: relative;
    top: -14px;
    margin-left: 135px;
}

.button_banner {
    position: absolute;
    margin-left: 148px;
    margin-top: 22px;
}

.banner_border {
    position: absolute;
    background: #3e3c3b;
    width: 1080px;
    left: 50%;
    margin-left: -540px;
    height: 30px;
    top: 52px;
    opacity: .07;
    z-index: 21;
}
