@font-face {
    font-family: 'areas';
    src:  url('../fonts/areas.eot?3236gi');
    src:  url('../fonts/areas.eot?3236gi#iefix') format('embedded-opentype'),
        url('../fonts/areas.ttf?3236gi') format('truetype'),
        url('../fonts/areas.woff?3236gi') format('woff'),
        url('../fonts/areas.svg?3236gi#areas') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="giarteicon-"], [class*=" giarteicon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'areas' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.giarteicon-banhos:before {
    content: "\e900";
}
.giarteicon-ferramentas:before {
    content: "\e901";
}
.giarteicon-joias:before {
    content: "\e902";
}
.giarteicon-ourivesaria:before {
    content: "\e903";
}

.or_com { font-family: 'Cormorant Infant', serif; }

