@media (max-width: 1199px) {

    .padding-section {
         padding-top: 47px;
         padding-bottom: 36px;
     }

    .padding-section_quem {
        padding-top: 47px;
        padding-bottom: 76px;
    }

    .menu_left {
        position: relative;
        left: -94px;
    }

    .menu_right {
        position: relative;
        left: -69px;
    }

    .home_border {
        width: 940px;
        margin-left: -470px;
    }

    .banner_border {
        width: 880px;
        margin-left: -440px;
    }

    .info-content-container {
        left: 22% !important;
    }

    .areas {
        margin: 35px 100px;
    }

    .history_button {
        left: 406px;
        top: -68px;
    }

    .stamp {
        right: 72px;
    }

    .big-area {
        margin-top: 60px;
    }

    .big-area .big-title {
        font-size: 52px;
    }

    .home_bottom {
        max-width: 314px;
        max-height: 427px;
    }

    .bottom_hover_border {
        width: 314px;
        height: 427px;
    }

    .bottom_hover_box2 {
        width: 255px;
        height: 370px;
    }

    .bottom_icons {
        top: -63px;
    }

    .gallery_small {
        width: 215px;
        height: 294px;
    }

    .gallery_small2 {
        width: 215px;
        height: 294px;
    }

    .gallery_big {
        width: 459px;
        height: 294px;
    }

    .gallery_big2 {
        width: 459px;
        height: 294px;
    }

    .filtros_row {
        text-align: center;
    }

    .filtros {
        margin-left: 0px;
        position: relative;
        height: auto;
        left: 50%;
        transform: translateX(-50%);
        z-index: 600;
    }

    .filtros_autor {
        margin-left: 0px;
        position: relative;
        height: auto;
        left: 50%;
        transform: translateX(-50%);
        z-index: 600;

    }

    .produto_row {
        margin:0;
        padding-left: 0px;
        padding-right: 0px;
        text-align: center;
    }

    .nav_in {
        height: auto;
        margin-left: 100px;
        padding-bottom: 5px;
    }

    .serv_rel_cont {
        padding: 0 !important;
    }

    .serv_rel_box2 {
        position: relative;
        margin-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        right: 0px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .serv_rel {
        position: absolute;
        left: 0;
        top: 0;
        width: 941px;
        height: 360px;
        outline: 30px solid rgba(64,62,61,.5);
        outline-offset: -30px;
        z-index: 400;
        margin-left: 15px;
        margin-right: 15px;
    }

    .serv_rel2 {
        position: absolute;
        right: 0;
        top: 0;
        left: 14px;
        width: 941px;
        height: 360px;
        outline: 30px solid rgba(206,150,100,.5);
        outline-offset: -30px;
        z-index: 400;
        margin-left: 0px;
        margin-right: 0;
        padding:0;
    }

    .serv_rel_box_img {
        position: relative;
        z-index: 300;
        width: 455px;
        height: 360px;
        overflow: hidden;

    }

    .serv_rel_box_img2 {
        position: relative;
        right:0;
        z-index: 300;
        width: 485px;
        height: 360px;
        overflow: hidden;

    }

    .serv_rel_box img {
        position: relative;
        z-index: 300;
        width: 570px;

    }

    .serv_rel_box2 img {
        position: relative;
        z-index: 300;
        width: 570px;
    }

    .serv_rel_text {
        position: relative;
        z-index: 300;
        width: 97%;
        text-align: center;
    }

    .serv_rel_text p {
        width: 378px;
    }

    .rel_icons {
        position: absolute;
        top: 91px;
        z-index: 450;
        left: 33%;
    }

    .slider_change_b {
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        width: 944px;
        height: 380px;
        outline: 30px solid rgba(64,62,61,0.5);
        outline-offset: -30px;
        z-index: 500;
        pointer-events: none;
    }

    .ba-slider {
        position: relative;
        width: 944px;
        height: 380px;
        overflow: hidden;
    }

    .ba-slider img {
        width: 944px;
        height: 380px;
        display:block;
    }

    .banhos_img1 {
        width: 458px;
        height: 298px;
    }

    .ferr_text {
        margin-top: 60px;
    }

    .artigos_left_img_box {
        width: 100%;
        height: 360px;
        overflow: hidden;
    }

    .artigos_left_img_box img {
        width: 276px;
        height: 360px;
        overflow: hidden;
    }

    .info_text {
        width: 500px;
        margin-left: 58px;
        margin-right: 0;
        text-align: left;
    }

    .info_form {
        margin-top: 40px;
        margin-left: 40px;

    }


}

/* Medium Devices, Desktops */
@media (max-width: 991px) {

    .menu_left {
        position: relative;
        left: -42px;
    }

    .menu_right {
        position: relative;
        top: -20px;
        left: 467px;
    }

    .logo-wrapper {
        position: absolute;
        left: 50%;
        margin-left: -100px;
        z-index: 40;
    }

    .social-media {
        position: absolute;
        right: 0;
        text-align: right;
    }

    .home_border {
        display: none;
    }

    .banner_border {
        display: none;
    }

    .info-content-container {
        left: 24% !important;
    }

    .equipa_img {
        margin-top: 40px;
        padding-left:0;
        margin-bottom: 70px;
    }

    .equipa_img2 {
        margin-top: 40px;
        padding-left:0;
        margin-bottom: 0px;
    }

    .history_button {
        position: absolute;
        top: -141px;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
    }

    .history .history-wrapper .history-dates-wrapper li .text {
        padding-left: 0px;
        padding-right: 0px;
    }

    .stamp {
        display: none;
    }

    .big-area {
        margin-top: 85px;
        margin-left: 0;
        text-align: center;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .exc_slider {
        right: auto;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    footer .newsletter-wrapper {
        padding-left: 60px;
        padding-right: 60px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }

    .subs_btn {
        margin-left: 0;
    }

    .send_newsl {
        left: 50%;
        transform: translateX(-50%);
        right: auto;
        top: auto;
        margin-top: 40px;
        margin-bottom: 30px;
    }

    footer .footer-contacts {
        margin-top: 60px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }

    footer .footer-contacts .title {
        text-align: center !important;
    }

    footer .footer-contacts .subtitle {
        text-align: center !important;
    }

    footer .footer-contacts .footer-social-media {
        margin-bottom: 40px;
    }

    footer .footer-contacts .contacts_bottom_form {
        padding-right: 40px;
        padding-left: 20px;
        margin-top: 20px;
        text-align: center !important;
    }

    .send_footer {
        position: relative;
        right: auto;
        margin-top: 48px;
    }

    .areas {
        margin: 35px 50px;
    }

    .gallery_small {
        width: 162px;
        height: 222px;
    }

    .gallery_small2 {
        width: 162px;
        height: 222px;
    }

    .gallery_big {
        width: 350px;
        height: 222px;
    }

    .gallery_big2 {
        width: 350px;
        height: 222px;
    }

    .filtros_first {
        width: 600px;
        display: block !important;
        float: left;
        margin-bottom: 10px;
    }

    .filtros_first a {
        width: 100%;
        display: block !important;
    }

    .dropdown-content3 {
        top: 20px;
    }

    .filtros_last:last-child {
        float: left;
        width: 100%;
        display: block !important;
    }

    .filtros_last:last-child a {
        float: left;
        width: 100%;
        display: block !important;
    }

    .filtros li {
        margin-bottom: 10px;
    }

    .dropdown-content3 li {
        margin-bottom: 0px;
    }

    .filtros_autor li {
        font-size: 14px;
        color: #002f6d;
        text-transform: uppercase;
        cursor: pointer;
        display: inline-block;
        margin-left: 0;
        margin-right: 30px;
        margin-bottom: 10px;
    }

    .icon_pres2 {
        display: block;
        position: relative;
        left: -31px;
    }

    .presente_box {
        height: 121px;
    }

    .text_pres {
        margin-right: 42px;
    }

    .icon_pres2 p {
        font-size: 20px;
        color: #aea8a4;
        float: left;
        margin-top: 8px;
        margin-left: 0px;
        margin-right: 15px;
        cursor: pointer;
    }

    .pres_btn {
        margin-left: 7px;
    }

    .serv_rel_box2 {
        display: flex;
        flex-direction: column;
    }

    .serv_rel {
        display: none;
    }

    .serv_rel2 {
        display: none;
    }

    .serv_rel_box_img {
        left: 50%;
        transform: translateX(-50%);

    }

    .serv_rel_box_img2 {
        width: 455px;
        left: 50%;
        transform: translateX(-50%);
        order: 1;
        margin-top: 40px;

    }

    .serv_rel_box img {
        position: relative;
        z-index: 300;
        width: 570px;

    }

    .serv_rel_box2 img {
        position: relative;
        z-index: 300;
        width: 570px;
    }

    .serv_rel_text {
        position: relative;
        z-index: 300;
        width: 97%;
        text-align: center;
        order: 2;
    }

    .serv_rel_text p {
        width: 378px;
    }

    .rel_icons {
        position: absolute;
        top: 91px;
        z-index: 450;
        left: 33%;
    }

    .slider_change_b {
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        width: 722px;
        height: 291px;
        outline: 30px solid rgba(64,62,61,0.5);
        outline-offset: -30px;
        z-index: 500;
        pointer-events: none;
    }

    .ba-slider {
        position: relative;
        width: 722px;
        height: 291px;
        overflow: hidden;
    }

    .ba-slider img {
        width: 722px;
        height: 291px;
        display:block;
    }

    .banhos_img1 {
        width: 346px;
        height: 226px;
    }

    .ferr_text {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .padding-section_ferr {
         padding-top: 30px;
         padding-bottom: 30px;
         padding-left: 0;
         padding-right:25px;
         text-align: center;
     }

    .padding-section_ferr img {
        width: 100%;

        padding: 0;
        margin:0;
    }

    .info_text {
        width: 450px;
        margin-left: 18px;
        margin-right: 0;
        text-align: left;
    }

    .info_form {
        margin-top: 40px;
        margin-left: 0px;

    }

    .input_small {
        width: calc(100% - 2em);
    }

    .filtros_col_box {
        float: left;
        margin-left: 115px;
    }

    .cons_img {
        margin-left: -240px;
    }

    .status_msg_news {
        position: absolute;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 339px;
        width: 100%;
    }

    .status_msg_footer {
        position: absolute;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 24px;
        width: 100%;
    }

    .status_msg_info1 {
        position: absolute;
        left: auto;
        margin-top: 20px;
        margin-left: 40px;
        padding-left: 15px;
        padding-right: 15px;
        z-index: 500;
        width: 100%;
        text-align: center;
    }

    .news_none {
        display:none;
    }

    .lb-nav a.lb-prev {
        opacity: .4;
    }

    .lb-nav a.lb-next {
        opacity: .4;
    }


}

/* Small Devices, Tablets */
@media (max-width : 767px){

    footer .newsletter-wrapper {
        padding-left: 0;
        padding-right: 0;
        margin: 30px -50px 30px -5px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }

    footer .footer-contacts .contacts_bottom_form {
        padding-right: 0px;
        padding-left: 0px;
        margin-left: -30px;
        margin-right: -30px;
        margin-top: 20px;
        text-align: center !important;
    }

    .send_footer {
        position: relative;
        right: auto;
        margin-top: 48px;
    }

    .footer_copy {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }

    footer .copy {
        color: #FFF;
        margin: 30px 0px 0px 0px;
        font-size: 13px;
    }

    footer .webcomum {
        float: none;
        margin-bottom: 30px;
    }

    footer .webcomum a {
        margin: 5px 0px;
    }

    .banner-home-slider {
        background-color: #f5e2d4;
        width: 100%;
        height: auto;
        padding-bottom: 50px;
        position: relative;
        left: auto;
        transform: none;
    }

    .banner-home-slider .banner-home-element .banner-home-box figure img {

        background-color: #f5e2d4;
        width: 100%;
        height: auto;
        min-height: 200px;
    }

    .banner-home-slider .banner-home-element  {

        background-color: #f5e2d4;
        border: 0;
        outline: 0;
    }

    .banner-home-slider .banner-home-element .banner-home-box  {

        background-color: #f5e2d4;
    }

    .banner-home-slider .banner-home-element .banner-home-box .info-content-container {

        background-color: #f5e2d4;
        width: 100%;
        top: 0;
        position: relative;
        left: 0;
        transform: translateX(-25%);
        z-index: 2;
        text-align: center;
    }

    .title_banner {
        width: 100%;
        margin-top: 35px;
        margin-left: 0px;
    }

    .subtitle_banner {
        position: relative;
        top: -6px;
        margin-left: 0px;
        width: 100%;
    }

    .button_banner {
        position: relative;
        margin-left: 0px;
        margin-top: 22px;
    }

    .slick-list {
        overflow: visible;
    }

    .slick-dots li {
        display: none;
    }

    .banner-home-slider .banner-home-element .banner-home-box .info-content-container .info-content .title {
        font-size: 40px;
    }

    .banner-home-slider .banner-home-element .banner-home-box .info-content-container .info-content .subtitle {
        font-size: 20px;
    }

    .areas {
        margin: 35px 0px;
    }

    .history .history-wrapper header .title {
        font-size: 40px;
    }

    .history .history-wrapper header .subtitle {
        font-size: 20px;
    }

    .big-area .big-title {
        font-size: 30px;
    }

    .menu_title {
        font-size: 40px;
    }

    .menu_desc {
        font-size: 20px;
        top: 0px;
    }

    .gallery_row {
        padding-right: 15px;
    }

    .gallery_small {
        width: 100%;
        height: auto;
        display: block;
    }

    .gallery_small2 {
        width: 100%;
        height: auto;
        display: block;
    }

    .gallery_big {
        width: 100%;
        height: auto;
        display: block;
    }

    .gallery_big2 {
        width: 100%;
        height: auto;
        display: block;
    }

    .gallery_small img {
        width: 100%;
        height: auto;
        display: block;
    }

    .gallery_small2 img {
        width: 100%;
        height: auto;
        display: block;
    }

    .gallery_big img {
        width: 100%;
        height: auto;
        display: block;
    }

    .gallery_big2 img {
        width: 100%;
        height: auto;
        display: block;
    }

    .filtros_row {
        text-align: left;
    }

    .filtros {
        margin-left: 0px;
        position: relative;
        height: auto;
        left: auto;
        transform: none;
        z-index: 600;
    }

    .prod_img_mob {
        text-align: center;
        margin:0;
        padding:0;
    }

    .prod_in img {
        margin-left: 0px;
    }

    .nav_in {
        height: auto;
        margin-left: 0px;
        padding-bottom: 5px;
        margin-top: 10px;
    }

    .nav_in li {
        padding-bottom: 5px;
    }

    .presente_box {
        height: 121px;
    }

    .presente_box_left {
        float: left;
        display: inline-block;
        margin-right: 20px;
        width: 100%;
    }

    .icon_pres2 {
        width: 100%;
        float: left;
        display: block;
        margin-left: 33px;
    }

    .pres_btn_box {
        width: 100%;
        text-align: center;
    }

    .icon_pres {
        font-size: 40px;
        color: #7d7d7d;
        float: left;
    }

    .text_pres {
        width: 200px;
        color: #7d7d7d;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        margin-top: 12px;
        float: left;
        margin-left: 16px;
    }

    .or_text {
        width: 100%;
    }

    .middle_title h2 {
        font-size: 40px;
    }

    .middle_title h3 {
        font-size: 20px;
    }

    .autor_box {
        display: flex;
        flex-direction: column;
    }

    .autor_img_box {
        order: 1;
        width: 100%;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: -20px;
    }

    .autor_img {
        width: 100%;
        height: auto;
    }

    .autor_text_box {
        order: 2;
        width: 100%;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: -90px;
    }

    .banhos_img1 {
        width: 100%;
        height: auto;
    }

    .change_mob_box {
        margin: 40px 0;
    }

    .change_mob {
        position: relative;
        padding: 0 15px;
    }

    .change_mob img {
        width: 100%;
        height: auto;
    }

    .slider_change_left_mob {
        background-color: #ce9664;
        padding: 10px 20px 10px 20px;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        opacity: 0.5;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        top: 0;
        z-index: 501;
    }

    .banhos_text {
        width: 100%;
        padding: 0 15px;
    }

    .banhos_text2 {
        width: 100%;
        padding: 0 15px;
    }

    .brincos_box {
        display: flex;
        flex-direction: column;
    }

    .brincos_img_box {
        order: 1;
        width: 100%;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: -20px;
    }

    .brincos_text_box {
        order: 2;
        width: 100%;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: -90px;
    }

    .cons_box {
        display: flex;
        flex-direction: column;
    }

    .cons_img_box {
        order: 1;
        width: 100%;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: -20px;
    }

    .cons_img {
        width: 100%;
        height: auto;
        margin-left: 0;
    }

    .cons_text_box {
        order: 2;
        width: 100%;
        text-align: center;
        padding-left: 0px;
        padding-right: 0px;
        margin-top: -90px;
    }

    .artigos_left {
        width: 100%;
        height: auto;
        padding: 30px 0;
        float: left;
        background: #999793;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
    }

    .artigos_right {
        width: 100%;
        height: auto;
        padding: 30px 0;
        float: right;
        background: #999793;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
    }

    .artigos_text {
        position: relative;
        top: 50px;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        text-align: center;

    }

    .artigos_left_img {
        width: 100%;
        height: 360px;
        float: left;
        position: relative;
        z-index: 450;
    }

    .artigos_right_img {
        width: 100%;
        height: 360px;
        float: right;
        position: relative;
        z-index: 450;
    }

    .info_img {
        display:none;
    }

    .info_text {
        width: 100%;
        margin-left: 18px;
        margin-right: 0;
        text-align: left;
        padding-right: 20px;
    }

    .info_form {
        margin-top: 40px;
        margin-left: 0px;

    }

    .input_small {
        width: calc(100% - 2em);
    }

    .info_btn_box {
        width: 100%;
        text-align: center;
        margin-left: -15px;
    }

    .info_btn {
        margin-top: 40px;
        margin-left: 0px;
        position: relative;
        z-index: 10;
        text-align: center;

    }

    .filtros_col_box {
        float: left;
        margin-left: 0px;
        right: auto;
        left: 0px;
    }

    .filtros_col_box p {
        margin-left: 15px;
    }

    .filtros_col_sep {
        display: none;
    }

    .check_mob {
        display: block;
    }

    .status_msg_info1 {
        position: absolute;
        left: auto;
        margin-top: 20px;
        margin-left: -30px;
        padding-left: 15px;
        padding-right: 15px;
        z-index: 500;
        width: 100%;
        text-align: center;
    }


}

@media (max-width : 587px){

    .produto_big_banner2 {
        zoom: 0.5; /* all browsers */
        -moz-transform: scale(0.5);  /* Firefox */
    }

}

@media (max-width : 500px){

    .serv_rel_text p {
        width: 100%;
    }

}

/* Extra Small Devices, Phones */
@media (max-width : 430px){

    .logo-wrapper {
        left: 20px;
        margin-left: 0;
    }

    .logo_shadow {
        left: 20px;
        top: 0px;
        margin-left: 0;
    }

    .menu_icon {
        margin-right: 0px;
    }

    .text_pres {
        width: 100%;
        margin-left: 0px;
        margin-top: -10px;
    }

    .presente_box {
        height: 164px;
        margin-bottom: 10px;
    }

}

@media (max-width : 365px){

}


@media (max-width : 361px){

}

