//cores
$primary-color:#5DBBD1;
$secundary-color: #AAEFFF;
$background-color: #000;
$header-color: #FFF;
$sidebar-color: #3a3e64;

$border-pagination-color: #3e3c3b;
$active-pagination-color: #3e3c3b;
$menu-mob-background-color: #5DBBD1;
$menu-el-color: #002f6d;
$banner-title-color: #ce9664;
$banner-subtitle-color: #7d7d7d;
$area-icon-color: #ce9664;
$area-title-color: #2b3c73;