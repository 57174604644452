.areas {
    margin: 35px 0;
    .areas-wrapper {
        list-style-type: none;
        li {
            margin: 0;
            padding: 0;
            outline: 4px solid transparent;
            outline-offset: 0px;
            @include transition;
            a {
                @include transition;
                padding: 20px;
                display: block;
                text-align: center;
                .area-icon {
                    font-size: 80px;
                    color: $area-icon-color;
                }
                .area-title {
                    margin-top: 13px;
                    text-transform: uppercase;
                    font-size: 16px;
                    color: $area-title-color;
                }
                .area-icon, .area-title {
                    @include transition;
                }
            }
            &:hover {
                a {
                    background-color: #ce9664;
                    .area-icon, .area-title {
                        color: #FFF;
                    }
                }
            }
        }
    }
}

.areas-wrapper li:hover{
    outline: 4px solid #ce9664;
    outline-offset: 5px;

}

.quemsomos {
    padding-right: 15px;
    .quemsomos-wrapper {
        header {
            margin-bottom: 60px;
            text-align: center;
            .title {
                font-family: 'Cormorant Infant', serif;
                font-weight: bold;
                font-size: 60px;
                color: #ce9664;
                text-transform: uppercase;
            }
            .subtitle {
                font-family: 'Cormorant Infant', serif;
                font-size: 30px;
                color: #7d7d7d;
                text-transform: uppercase;
            }
        }
        .text {
            margin-top: 0;
            font-size: 14px;
            line-height: 17px;
            h2 {
                font-size: 18px;
                font-weight: bold;
                color: #7d7d7d;
                line-height: 21px;
            }
        }
        img {
            margin-left: 15px;
        }
    }
}

.history {
    .history-wrapper {
        header {
            margin-bottom: 60px;
            text-align: center;
            .title {
                font-family: 'Cormorant Infant', serif;
                font-size: 60px;
                font-weight: bold;
                color: #ce9664;
                text-transform: uppercase;
            }
            .subtitle {
                font-family: 'Cormorant Infant', serif;
                font-size: 30px;
                color: #7d7d7d;
                text-transform: uppercase;
            }
        }
        .history-dates-wrapper {
            border-top: 1px solid #555555;
            li {
                padding-top: 50px;
                text-align: center;
                .date, .title {
                    color: #dfd8d2;
                }
                .date {
                    font-family: 'Cormorant Infant', serif;
                    font-size: 30px;
                    font-weight: bold;
                }
                .title {
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: bold;
                }
                .text {
                    font-size: 14px;
                    color: #7d7d7d;
                    padding-left: 40px;
                    padding-right: 40px;
                }
            }
        }
    }
}

.big-area {
    margin-top: 85px;
    margin-left: 50px;
    text-align: center;
    .big-area-icon {
        font-size: 95px;
        color: #d29f71;
    }
    .small-title {
        font-size: 25px;
        color: #002f6d;
        padding-bottom: 20px;
        padding-top: 15px;
        text-transform: uppercase;
    }
    .big-title {
        font-size: 60px;
        font-weight: bold;
        color: #ce9664;
        padding-bottom: 40px;
        text-transform: uppercase;
        font-family: 'Cormorant Infant', serif;
    }
}

.bg-history {
    background-color: #403e3d;
}
.bg-quemsomos {
    background-color: #f4f3ec;
}

.padding-section {
    padding-top: 87px;
    padding-bottom: 66px;
}

.padding-section_ferr {
    padding-top: 60px;
    padding-bottom: 66px;
}

.stamp {
    position: absolute;
    top: -132px;
    right: 142px;
}

.home_border {
    pointer-events: none;
    background: transparent;
    position: fixed;
    width: 1140px;
    height: 110%;
    border-top: 0;
    border-left: 30px solid #3e3c3b;
    border-right: 30px solid #3e3c3b;
    left: 50%;
    margin-left: -570px;
    z-index: 10;
    opacity: .07;
}

.history_button {
    position: absolute;
    top: -107px;
    left: 510px;
    cursor: pointer;
}

.exc_slider {
    position: relative;
    right: -15px;
}

.bottom_cards {
    background-color: #ce9664;
}

.home_bottom_box {
    margin-left: 15px;
    position: relative;
    @include transition2;
    overflow: hidden;
}

.home_bottom {
    max-width: 380px;
    max-height: 517px;
    margin: 0;
    padding: 0;
    z-index: 30;
}

.left-10 {
    margin-left: -10px;
}

.bottom_icons {
    position: absolute;
    left:0;
    top:0;
    text-align: center;
    width: 100%;
    margin-top: 175px;
}

.area-icon-b {
    font-size: 118px;
    color: $area-icon-color;
    opacity: 1;
    @include transition2;
}
.area-title-b {
    text-transform: uppercase;
    font-size: 16px;
    color: $area-icon-color;
    margin-top: 20px;
    opacity: 1;
    @include transition2;
}

.plus_bottom {
    background-image: url("../images/plus_bottom.svg");
    width: 56px;
    height: 56px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    opacity: 1;
    @include transition2;
}

.bottom_hover_box {
    opacity: 0;
    @include transition2;
}

.home_bottom_box:hover .bottom_hover_box {
    opacity: 1;
}

.home_bottom_box:hover .area-title-b {
    opacity: 0;
}

.home_bottom_box:hover .area-icon-b {
    opacity: 0;
}

.home_bottom_box:hover .plus_bottom {
    opacity: 0;
}

.bottom_hover_border {
    background: transparent;
    position: absolute;
    left: 0;
    top: 0;
    width: 380px;
    height: 517px;
    z-index: 40;
    outline: 30px solid $area-icon-color;
    outline-offset: 30px;
    opacity: 0.5;
    @include transition2;
}

.home_bottom_box:hover .bottom_hover_border {
    outline-offset: -30px;
}

.bottom_hover_box2 {
    background: $area-icon-color;
    position: absolute;
    width: 260px;
    height: 398px;
    overflow: auto;
    margin: auto;
    top: 0; left: -10px; bottom: 0; right: 0;
    z-index: 41;
    opacity: 0.8;
}

.bottom_text_box {
    position: absolute;
    width: 200px;
    height: 398px;
    overflow: auto;
    margin: auto;
    top: 150px; left: -10px; bottom: 0; right: 0;
    z-index: 42;
    text-align: center;
    @include transition2;
}

.home_bottom_box:hover .bottom_text_box {
    top: 200px;
}

.bottom_hover_title {
    margin-top: 13px;
    text-transform: uppercase;
    font-size: 16px;
    color: #fff;
    font-weight: bold;
}

.bottom_hover_text {
    font-size: 13px;
    color: #fff;
    margin-top: 20px;
}


