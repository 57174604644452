.slick-slider {
    .slick-arrow {
        @include absolutecenter("y");
        z-index: 10;
        cursor: pointer;
        font-size: 40px;
        color: #FFF;
    }
    .fa-angle-left {
        left: 20px !important;
    }
    .fa-angle-right {
        right: 20px !important;
    }
}

.home-slider {
    margin: 30px 0px;
}

.slick-next {
    right: 0px;
}

.slick-prev {
    left: 0px;
}

.slick-dots {
    bottom: 20px;
    li {
        width: 10px;
        height: 10px;
        border: 2px solid $border-pagination-color;
        border-radius: 50%;
        button {
            display: none;
        }
    }
}
.slick-active {
    background-color: $border-pagination-color;
}

.paraxify{
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
}

.parallax-bg-home {
    margin-top: 40px;
    background-image: url(../images/banner-paraxify.jpg);
    min-height: 400px;
    width: 100%;
    background-repeat: no-repeat;
}

/*inputs*/

.input {
    position: relative;
    z-index: 1;
    display: inline-block;
    width: calc(100% - 2em);
    vertical-align: top;
}

.input_small {
    position: relative;
    z-index: 1;
    display: inline-block;
    width: 45%;
    vertical-align: top;
    margin-right: 30px;
}


.input__field {
    position: relative;
    display: block;
    float: right;
    padding: 0.8em;
    width: 100%;
    border: none;
    border-radius: 0;
    background: #f0f0f0;
    color: #aaa;
    font-weight: bold;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-appearance: none; /* for box shadows to show on iOS */
}

.input__field:focus {
    outline: none;
}

.input__label {
    display: inline-block;
    float: right;
    width: 40%;
    color: #aea8a4;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.input__label-content {
    position: relative;
    display: block;
    padding: 1.2em 0;
    width: 100%;
}

.graphic {
    position: absolute;
    top: 0;
    left: 0;
    fill: none;
}

.icon {
    color: #ddd;
    font-size: 150%;
}

/* Individual styles */

/* Hoshi */
.input--hoshi {
    overflow: hidden;
    text-transform: uppercase;
}

.input__field--hoshi {
    font-weight: bold;
    font-size: 14px;
    margin-top: 1em;
    padding: 1.5em 0.15em;
    width: 100%;
    background: transparent;
    color: #7d7d7d;
}

.input__label--hoshi {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 0.25em;
    width: 100%;
    height: calc(100% - 1em);
    text-align: left;
    pointer-events: none;
}

.input__label-content--hoshi {
    position: absolute;
    font-weight: normal;
    font-size: 14px;
    color: #7d7d7d;
}

.input__label--hoshi::before,
.input__label--hoshi::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 10px);
    border-bottom: 1px solid #B9C1CA;
}

.input__label--hoshi::after {
    margin-top: 2px;
    border-bottom: 3px solid red;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
}

.input__label--hoshi-color-1::after {
    border-color: #ce9664;
}

.input__label--hoshi-color-2::after {
    border-color: #ce9664;
}

.input__label--hoshi-color-3::after {
    border-color: #ce9664;
}

.input__field--hoshi:focus + .input__label--hoshi::after,
.input--filled .input__label--hoshi::after {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.input__field--hoshi:focus + .input__label--hoshi .input__label-content--hoshi,
.input--filled .input__label-content--hoshi {
    -webkit-animation: anim-1 0.3s forwards;
    animation: anim-1 0.3s forwards;
}

@-webkit-keyframes anim-1 {
    50% {
        opacity: 0;
        -webkit-transform: translate3d(1em, 0, 0);
        transform: translate3d(1em, 0, 0);
    }
    51% {
        opacity: 0;
        -webkit-transform: translate3d(-1em, -40%, 0);
        transform: translate3d(-1em, -40%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate3d(0, -40%, 0);
        transform: translate3d(0, -40%, 0);
    }
}

@keyframes anim-1 {
    50% {
        opacity: 0;
        -webkit-transform: translate3d(1em, 0, 0);
        transform: translate3d(1em, 0, 0);
    }
    51% {
        opacity: 0;
        -webkit-transform: translate3d(-1em, -40%, 0);
        transform: translate3d(-1em, -40%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate3d(0, -40%, 0);
        transform: translate3d(0, -40%, 0);
    }
}


.lightboxOverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: black;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8;
    display: none;
}

.lightbox {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 10000;
    text-align: center;
    line-height: 0;
    font-weight: normal;
}

.lightbox .lb-image {
    display: block;
    height: auto;
    max-width: inherit;
    max-height: none;
    /* Image border */
    border: 4px solid #ce9664;
}

.lightbox a img {
    border: none;
}

.lb-outerContainer {
    position: relative;
    *zoom: 1;
    width: 250px;
    height: 250px;
    margin: 0 auto;

    /* Background color behind image.
       This is visible during transitions. */
    background-color: white;
}

.lb-outerContainer:after {
    content: "";
    display: table;
    clear: both;
}

.lb-loader {
    position: absolute;
    top: 43%;
    left: 0;
    height: 25%;
    width: 100%;
    text-align: center;
    line-height: 0;
}

.lb-cancel {
    display: block;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    background: url(../images/lightbox/loading.gif) no-repeat;
}

.lb-nav {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
}

.lb-container > .nav {
    left: 0;
}

.lb-nav a {
    outline: none;
    background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
}

.lb-prev, .lb-next {
    height: 100%;
    cursor: pointer;
    display: block;
}

.lb-nav a.lb-prev {
    width: 34%;
    left: 0;
    float: left;
    background: url(../images/lightbox/prev.png) left 48% no-repeat;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transition: opacity 0.6s;
    -moz-transition: opacity 0.6s;
    -o-transition: opacity 0.6s;
    transition: opacity 0.6s;
}

.lb-nav a.lb-prev:hover {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
}

.lb-nav a.lb-next {
    width: 64%;
    right: 0;
    float: right;
    background: url(../images/lightbox/next.png) right 48% no-repeat;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transition: opacity 0.6s;
    -moz-transition: opacity 0.6s;
    -o-transition: opacity 0.6s;
    transition: opacity 0.6s;
}

.lb-nav a.lb-next:hover {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
}

.lb-dataContainer {
    margin: 0 auto;
    padding-top: 5px;
    *zoom: 1;
    width: 100%;
    -moz-border-radius-bottomleft: 4px;
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -webkit-border-bottom-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
    content: "";
    display: table;
    clear: both;
}

.lb-data {
    padding: 0 4px;
    color: #ccc;
}

.lb-data .lb-details {
    width: 85%;
    float: left;
    text-align: left;
    line-height: 1.1em;
}

.lb-data .lb-caption {
    font-size: 13px;
    font-weight: bold;
    line-height: 1em;
}

.lb-data .lb-caption a {
    color: #4ae;
}

.lb-data .lb-number {
    display: block;
    clear: left;
    padding-bottom: 1em;
    font-size: 12px;
    color: #999999;
}

.lb-data .lb-close {
    display: block;
    float: right;
    width: 30px;
    height: 30px;
    background: url(../images/lightbox/close.png) top right no-repeat;
    text-align: right;
    outline: none;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
    cursor: pointer;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
}

.hidden_gallery {
    display: none;
}

