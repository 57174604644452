footer {
    .newsletter-wrapper {
        position: relative;
        margin: 30px 0 30px 0;
        background-color: #464646;
        .title {
            font-size: 30px;
            color: #ce9664;
            text-transform: uppercase;
            font-family: 'Cormorant Infant', serif;
            font-weight: bold;
            margin: 0;
        }
        .subtitle {
            font-size: 20px;
            color: #7d7d7d;
            text-transform: uppercase;
            font-family: 'Cormorant Infant', serif;
            margin-top: 0;
        }
    }
    .footer-contacts {
        margin-top: 90px;
        .title, .subtitle {
            text-transform: uppercase;
            font-family: 'Cormorant Infant', serif;
            margin: 0px;
        }
        .title {
            font-size: 30px;
            font-weight: bold;
            color: #ce9664;
        }
        .subtitle {
            font-size: 20px;
            color: #7d7d7d;
        }
        address {
            margin-top: 25px;
            text-transform: uppercase;
            color: #aea8a4;
        }
        .contact-info {
            margin-bottom: 55px;
            color: #aea8a4;
            li {
                margin-top: 3px;
                i {
                    margin-right: 7px;
                }
                a {
                    @include transition;
                    color: #aea8a4;
                    &:hover {
                        color: #FFF;
                    }
                }
            }
        }
        .footer-social-media {
            li {
                @include transition;
                margin-right: 7px;
                cursor: pointer;
                display: inline-block;
                position: relative;
                border: 3px solid #FFF;
                border-radius: 50%;
                height: 40px;
                width: 40px;
                a {
                    display: block;
                    color: #FFF;
                    i {
                        position: absolute;
                        top: 50%;
                        @include absolutecenter('both');
                    }
                }
                &:hover {
                    background-color: #FFF;
                    a i {
                        color: #ce9664;
                    }
                }
            }
        }
    }
    .copy {
        color: #FFF;
        margin: 20px 0px;
        font-size: 13px;
    }
    .webcomum {
        float: right;
        a {
            display: block;
            margin: 20px 0px;
        }
    }
}

/* html {
    height: 100%;
}

body {
    min-height: 100%;
    @include flexbox;
    @include flex-direction(column);
}
.wrapper {
    @include flex(1);
}
*/

.send_newsl {
    position: relative;
    right: -50px;
    top:15px;
}

.send_footer {
    position: relative;
    right: 8px;
    margin-top: 15px;
}

.contact_section {
    position: relative;
    padding-bottom: 60px;
}

.back_top {
    position: absolute;
    top: 0;
    left: 472px;
}

.email {
    padding-top: 20px;
}

.webc {
    color: #FFF;
    margin: 20px 0px;
    font-size: 13px;
    @include transition;
}

.webc:hover {
    color: $area-icon-color;
}

.news_input {
    margin-top: -5px;
}

.news_input_titles {
    margin-top: 11px;
}

.subs_btn {
    margin-top: 4px;
    margin-left: -10px;
}
