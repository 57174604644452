.menu-wrapper {
    margin: 20px 0;
    float: left;
    text-align: center;
    ul{
        list-style: none;
        margin:0;
        padding:0;
        li{
            margin: 0px 15px;
            display: inline-block;
            text-align: center;
            a{
                text-transform: uppercase;
                font-size: 14px;
                color: $menu-el-color;
                &:hover, &:focus{
                    color: #1B7686;
                    text-decoration: none;
                    @include transition;
                }
            }
        }
    }
}

.select-lang-wrapper {
    .select-lang {
        cursor: pointer;
        width: 50px;
        float: right;
        border-radius: 0;
        font-size: 12px;
        padding: 5px;
        margin: 10px 0px;
    }
}

.hamburger {
    text-align: right;
    cursor: pointer;
    i {
        font-size: 20px;
        color: $primary-color;
    }
}

.logo-wrapper {
    position: absolute;
    left: 50%;
    margin-left: -100px;
    z-index: 40;
}

.logo_shadow {
    position: absolute;
    width: 200px;
    height: 75px;
    left: 50%;
    top: 0px;
    margin-left: -100px;
    z-index: 20;
    -webkit-border-radius: 19px 20px 10px 10px;
    -moz-border-radius: 19px 20px 10px 10px;
    border-radius: 19px 20px 10px 10px;

    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5);
}

.close-icon {
    cursor: pointer;
    padding: 20px !important;
}
.social-media {
    position: absolute;
    right: 0;
    text-align: right;
    a {
        margin-left: 10px;
        i {
            @include transition;
            font-size: 20px;
            color: #b2aca8;
            &:hover {
                color: #ce9664;
            }
        }
    }

}

.menu_shadow {
    background: #fff;
    position: relative;
    z-index: 39;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.42);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.42);
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.42);
}

.menu {
    position: relative;
    width: 100%;
    height: 51px;
    padding-top: 18px;
    li {
        display: inline-block;
        a {
            @include transition;
            font-size: 14px;
            color: $menu-el-color;
            display: inline-block;
            text-transform: uppercase;
            &:hover {
                color: #ce9664;
            }
        }
        .menu-item {
            padding: 0 14px 0 36px;
        }
    }
}

.menu_left {
    position: relative;
    left: -27px;
}

.menu_right {
    position: relative;
    left: -103px;
}

.dropbtn {
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown:hover .menu-item  {
    color: #ce9665;
}

.dropdown-content {
    display: none;
    position: absolute;
    padding-top: 13px;
    left: -4px;
    width: 177px;
    height: auto;
    z-index: 600;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    width: 100%;
    text-transform: none !important;
}

.dropdown-content a:hover {
    background-color: #fff;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content li {
    display: block;
    background: #ecd7c5;
    width: 100%;
    text-transform: none !important;
    text-align: center;
}

.dropdown-content2 {
    display: none;
    position: absolute;
    padding-top: 13px;
    left: -7px;
    width: 198px;
    height: auto;
    z-index: 600;
}

.dropdown-content2 a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    width: 100%;
    text-transform: none !important;
}

.dropdown-content2 a:hover {
    background-color: #fff;
}

.dropdown:hover .dropdown-content2 {
    display: block;
}

.dropdown-content2 li {
    display: block;
    background: #ecd7c5;
    width: 100%;
    text-transform: none !important;
    text-align: center;
}

.dropdown-content3 {
    display: none;
    position: absolute;
    padding-top: 11px;
    left: -7px;
    width: auto;
    height: auto;
    z-index: 600;
}

.dropdown-content3 a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    width: 100%;
    text-transform: none !important;
}

.dropdown-content3 a:hover {
    background-color: #fff;
}

.dropdown:hover .dropdown-content3 {
    display: block;
}

.dropdown-content3 li {
    display: block;
    background: #ecd7c5;
    width: 100%;
    text-transform: none !important;
    text-align: left;
}

.dropdown-content4 {
    display: none;
    position: absolute;
    padding-top: 9px;
    left: 10px;
    width: auto;
    min-width: 131px;
    height: auto;
    z-index: 600;
}

.dropdown-content4 a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    width: 100%;
    text-transform: none !important;
}

.dropdown-content4 a:hover {
    background-color: #fff;
}

.dropdown:hover .dropdown-content4 {
    display: block;
}

.dropdown-content4 li {
    display: block;
    background: #ecd7c5;
    width: 100%;
    text-transform: none !important;
    text-align: left;
}

.menu_active {
    color: #ce9665 !important;
}

.menu_icon {
    background-image: url('../images/menu-01.svg');
    width: 27px;
    height: 20px;
    float: right;
    margin-right: 10px;
    margin-top: 0px;
}

.modal-content {
    text-align: center;
}

.modal-dialog {
    margin: 0 !important;
    padding: 10px !important;
}

.or_close_box {
     background: #CE9664;
     width: 100%;
    height: 50px;
    position: relative;
 }

.menu_mob_title {
    background: url('../images/logo_mob-01.svg');
    width: 85px;
    height: 24px;
    float: left;
    margin-left: 10px;
    margin-top: 8px;
    margin-bottom: 20px;
}

.or_close i {
    font-size: 20px;
    color: #a77049;
    float: right;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    cursor: pointer;
}

.modal_content_li {

    border-bottom: 4px solid #CE9664;
}

.modal_content_li a {
    background: #fff;
    width: 100%;
    display: block;
    padding: 20px 20px;
    font-size: 16px;
    text-transform: uppercase;
    color: $menu-el-color;
}

.modal_content_b button {
    background: #fff;
    width: 100%;
    display: block;
    padding: 20px 20px;
    font-size: 16px;
    text-transform: uppercase;
    color: $menu-el-color;
    border-bottom: 4px solid #CE9664;
}

.modal_content_b span {
    margin-left: 8px;
}

.modal_content_b span i {
    color: #CE9664;
}

.modal_content_li_in {

    border-bottom: 4px solid #CE9664;
}

.collapse {
    margin: 0;
    border: 0;
    padding: 0;
}

.modal_content_li_in a {
    background: #ecd7c5;
    width: 100%;
    display: block;
    padding: 20px 20px;
    font-size: 16px;
    text-transform: uppercase;
    color: $menu-el-color;
}
